<template>
    <!-- 应该用elment 后面再改 -->
    <div class="voucher-container">
        <div class="modal">
            <div class="modal-content" style="background-color: #fff">
                <h3>卡卷详细信息</h3>
                <p>卡卷账号：{{ voucherInfo.number }}</p>
                <p>卡卷密码：{{ voucherInfo.password }}</p>
                <p>卡卷兑换时间：{{ $util.timeStampTurnTime(voucherInfo.useTime) }}</p>
                <p>卡卷开始时间：{{ voucherInfo.BeginTime }}</p>
                <p>卡卷结束时间：{{ voucherInfo.endTime }}</p>
                <button @click="confirmExchange">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters,mapState } from "vuex"
    
    export default {

        computed: {
            ...mapState('app',['voucherInfo']),
        },

        methods: {
            confirmExchange() {
                    this.$router.push("/index")
                }
            }
        }

</script>

<style>
    .voucher-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #fa0001;
    }

    .voucher-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 30px;
    }

    .voucher-header {
        text-align: center;
        margin-bottom: 30px;
    }

    .voucher-header h2 {
        font-size: 24px;
        color: #333;
        margin: 0;
        padding: 0;
    }

    .voucher-body input {
        padding: 12px;
        font-size: 16px;
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 10px;
        outline: none;
        transition: border-color 0.3s ease-in-out;
    }

    .voucher-body button {
        padding: 12px 24px;
        font-size: 16px;
        cursor: pointer;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 10px;
        width: 100%;
        transition: background-color 0.3s ease-in-out;
    }

    .voucher-body button:hover {
        background-color: #45a049;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        text-align: center;
    }

    .modal-content h3 {
        margin-bottom: 20px;
    }

    .modal-content p {
        margin-bottom: 10px;
    }

    .modal-content button {
        padding: 12px 24px;
        font-size: 16px;
        cursor: pointer;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 10px;
        transition: background-color 0.3s ease-in-out;
    }

    .modal-content button:hover {
        background-color: #45a049;
    }
</style>
